.on_hover_item:hover{

  background-color: #cfd2d1 !important;

}
.linkContainer {
  width: 100%;
}
.admin_menu{

  position: absolute;

  /* left: 40%; */

}

.admin_logo{

  color: #fff;

}

.my-account{
  width: 250px;
 display: flex;
}
.nav-link {
  padding: 0px 12px 0px 12px
}
.dropdown_nav_anchor{
  border-radius: 0px;
  text-decoration: none;
  font-size: 1.2rem;
font-weight: 700;
padding: 0px 12px 0px 12px
}

.text_styling_anchor, .admin_styling{

  font-size: 1.2rem;
font-weight: 700;

}
.ant-dropdown-menu {
  padding: 4px;
  list-style-type: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0px!important;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

/* mobile nav designs ..  */

@media (max-width:600px){
/* .nav_bar{
  position: fixed !important;
  background-color: #353bb5 !important;
} */
/* .nav_bar_container{
position: fixed !important;
} */
}