

.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}



.signup-box {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 450px;
  text-align: center;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

p {
  color: #666;
  font-size: 14px;
}

.input-group {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.input-group input {
  width: 50%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.input-group.full {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-group.full input {
  width: 100%;
}

.terms {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 10px 0;
}

.terms input {
  margin-right: 8px;
}

.terms label a {
  color: #ff5733;
  text-decoration: none;
  font-weight: bold;
}

.signup-button {
  background: #ff5733;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.signup-button:hover {
  background: #e64a19;
}

.login-link {
  margin-top: 10px;
  font-size: 14px;
}

.login-link a {
  color: #ff5733;
  text-decoration: none;
  font-weight: bold;
}

.social-login {
  margin-top: 20px;
  font-size: 14px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-icons button {
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.social-icons .facebook {
  background: #3b5998;
}

.social-icons .google {
  background: #db4437;
}

.social-icons .apple {
  background: black;
}
