.footer {
    background-color: #021838;
    color: white;
    padding: 20px 40px;
    font-family: Arial, sans-serif;
  }
  
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    align-items: end;
  }
  
  .logo-bold {
    color: white;
  }
  
  .logo-blue {
    color: #4A90E2;
  }
  
  .footer-nav {
    display: flex;
    gap: 20px;
  }
  
  .footer-nav a {
    color: white;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
    font-size: 18px;
  }
  
  .social-icons svg {
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .social-icons svg:hover {
    transform: scale(1.1);
  }
  
  .footer-line {
    margin: 20px 0;
    border: 0;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
  }
  
  .footer-links {
    display: flex;
    gap: 20px;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  