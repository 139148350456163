.register{
    background: -webkit-linear-gradient(left, #3931af, #00c6ff);
    margin-bottom: 1%;
    padding-right: 14%; 
    padding-left: 14%;
    padding-top: 5%;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    /* height: 100vh;
    padding-bottom: 3%;
    margin-bottom: 3%; */
}
.register-left{
    text-align: center;
    color: #fff;
    margin-top: 4%;
}
.register-left input{
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;
    background: #f8f9fa;
    font-weight: bold;
    color: #232527;

    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
}
.register-right{
    background: #f8f9fa;
    border-top-left-radius: 10% 50%;
    border-bottom-left-radius: 10% 50%;

    /* border-top-right-radius: 10% 50%;
    border-bottom-right-radius: 10% 50%; */
}
.register-left img{
    margin-top: 15%;
    margin-bottom: 5%;
    width: 25%;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
.register-left p{
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
}
.register .register-form{
    padding: 10%;
    margin-top: 10%;
    font-family: Arial, Helvetica, sans-serif;
}
.barcodeimage img{
    
height: 80%;
width: 100%;
}
.fetcheddata{
    font-size: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
}
.btnRegistersubmit{
    background: #0062cc;
    border: none;
    border-radius: 1.5rem;
    padding: 1%;
    cursor: pointer;
   margin-bottom: 20%;
    width:50%;
    margin-top: 8%;
    color:aliceblue;
    font-size: 18px;
}
.register .nav-tabs{
    margin-top: 3%;
    border: none;
    background: #0062cc;
    border-radius: 1.5rem;
    width: 28%;
    float: right;
}
.register .nav-tabs .nav-link{
    padding: 2%;
    height: 34px;
    font-weight: 600;
    color: #fff;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover{
    border: none;
}
.register .nav-tabs .nav-link.active{
    width: 100px;
    color: #0062cc;
    border: 2px solid #0062cc;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}
/* .register-heading{
    text-align: center;
    margin-top: 8%;
    margin-bottom: -15%;
    color: #1b1c1d;
} */

.register-head{
    text-align: center;
    margin-top: 8%;
    margin-bottom: -15%;
    color: #1b1c1d;
}
.vecto-header{
    margin-top: 8rem;
    color: azure;
}
.header-reg{
    color: azure;
}