
/* Event List CSS Styling... */

.event_div{
  
    margin-top: 8rem;
}
.my_event_table{
    margin-top: 1rem;
}
.event_btn_div{
    
    align-items: right;
    text-align: right;
    height: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;  
}
.create_event_btn{
    cursor: pointer;
    text-align: right;
}
.container-user{
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
}
.event_btn_{
    padding-bottom: 20px;
}
/* ********************************** */

/* Create Event List Styling  */


.header_heading{
    height: 5rem;
    margin-top: 8rem;
    text-align: center;
}
.create_event_div{
    height: 5rem;
    border: 1px solid black;
    display:  flex;
    justify-content: space-around;
    align-items: center;
}
.date_range_picker{
    font-size: 1.5rem;
    border: 1px solid black;
}
.upload_btn{
    margin-right: 2rem;
    width: 7rem;
    cursor: pointer;
}

/* ************************************************ */