.display-3{
    /* margin-top: 200px; */
    color: #2B2D2F;
}

.lead{
    margin-top: 3.5rem;
}

.order-id{
    font-size: 1rem;
}
.name-styling{
    margin-top: 2rem;
}
.thumbs{
    margin-top: 110px;
    height: 12rem;
    width: 12rem;
}
