h1 {
    color: coral;
}
.grid-container {
    columns: 5 200px;
    column-gap: 1.5rem;
    width: 90%;
    margin: 0 auto;
}
.grid-container div {
    width: 150px;
    margin: 0 1.5rem 1.5rem 0;
    display: inline-block;
    width: 100%;
    border: solid 2px black;
    padding: 5px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
}
/* .grid-container a:hover img {
		filter: grayscale(0);
	  } */
.grid-container a:hover {
    border-color: coral;
}
.gallery {
    /* filter: grayscale(100%); */
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    margin-bottom: 1em;
}


  
  
  
  .row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;

    }
  } 

  

.reg2{
    background: -webkit-linear-gradient(left, #e1e0f8, #d9ebf0);
    margin-bottom: 1%;
    padding: 4%;
    padding-right: 0%; 
    padding-left: 0%; 
    width: 100%;
    height: 100%;
    border-radius: 12px;
    
}