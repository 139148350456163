.form-control{
font-size: 16px;
}

.labels{
    font-size: 18px;
}
.text-right h2{
    text-align: center;
}
button.profile-button{
    background: #0062cc;
    border: none;
    border-radius: 1.5rem;
    padding: 1%;
    cursor: pointer;

    width:20%;
    margin-top: 8%;
    color:aliceblue;
    font-size: 18px;
    text-align: center;
}

@media (max-width:600px){
.register {
    background: -webkit-linear-gradient(left, #3931af, #00c6ff);
    margin-bottom: 1%;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 19%;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}
button.profile-button {
    
    width: 48%;}
    .fetcheddata {
        font-size: 18px;}

}