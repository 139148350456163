.Back{
 
 margin-bottom:  0% ;
}
.reg1{
   
    margin-bottom: 1%;
    padding: 4.5%;
    padding-right: 10%; 
    width: 100%;
    height: 100%;
    border-radius: 12px;
    
}
.reg1-left{
    text-align: center;
    color: #fff;
    margin-top: 2%;
}
/* Styles for the card container */
.card {
    width: 300px; /* Adjust the width as needed */
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .eventinfoform{
    padding-top: 8rem;
    overflow: hidden;
  }
  .card-image-d{
    width: 100%;
    height: 70%;
  }
  .hidelogin{
    margin-left: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .card{
    width:auto;
    height: 500px;
  }
  .register-btn-event{
    background: #0062cc;
    border: none;
    border-radius: 1.5rem;
    padding: 1%;
    cursor: pointer;
  margin-bottom: 4%;
    width:50%;
    margin-top: 4%;
    color:aliceblue;
    font-size: 18px;
  }
  .headingevent h1{
    color: black;
    text-align: center;
    padding-bottom: 2rem;
  }
  /* Styles for the card image */
  .card-image {
    width: 100%;
    height: 100%;
  }
  
  /* Styles for the card content */
  .card-content {
    padding: 10px;
    background-color: #f8f8f8;
  }
  
  /* Styles for the card title */
  .card-title {
    font-size: 18px;
    margin: 0;
    color: #333;
  }
  
.reg1-right{
    background: #f8f9fa;
    border-top-left-radius: 10% 50%;
    border-bottom-left-radius: 10% 50%;
}
.reg1 .reg1-form{
    padding: 8%;
    margin-top: 0%;
    font-family: Arial, Helvetica, sans-serif;
}
.register-btn{
    float:center;
    margin-top: 0%;
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    background: #0062cc;
    color: #fff;
    font-weight: 600;
    width: 30%;
    cursor: pointer;
    margin-right:100%;
    
}

.reg-heading{
    text-align: center;
    margin-top: 5%;
    margin-bottom: -15%;
    color: #1b1c1d;
}
/* .vecto-header{
    margin-top: 10rem;
    color: azure;
} */


@media (max-width:600px){
  .modal-image{
    width:100%!important;
  }
}