.card{
    height:400px;
}
.card .row .icheck-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.signin{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.signinbuttom{
    padding-top: 20px;
    padding-bottom: 20px;
    }
div.Heading{
    padding-top: 50px;
    padding-bottom: 50px;
}

.signin button{
    min-width: 250px !important;
    margin-top: 10px;
}

.card .row p{
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-wrapper{
   height: 800px;

}
.form-horizontal .control-label{
    text-align: left;
}
@media print {
    body{
        background-image: none;
    }
}

/* for Desktop Mode screen styling... */

@media (min-width:1440px) and (max-width:2200px){
    .login_temp_parent_div{
        display: flex;
        justify-content: center;
     }
   .login-temp{
     width: 40%;
     padding: 30px;
 
   }
}

@media (min-width:1024px) and (max-width:1440px){
    .login_temp_parent_div{
        display: flex;
        justify-content: center;
     }
   .login-temp{
     width: 40%;
     padding: 30px;
 
   }
}

/* for Ipad Mode screen styling... */

@media (min-width:768px) and (max-width:1024px){
    .login_temp_parent_div{
       display: flex;
       justify-content: center;
    }
  .login-temp{
    width: 40%;
    padding: 30px;

  }
}


/* for mobile Mode screen styling... */

@media (max-width:600px){
    .login_temp_parent_div{
        display: flex;
        justify-content: center;
     }
    .login-temp {
        margin: 10px;
        padding: 10px;
    }
}



.login-container {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* height: 100vh; */
    background-color: #f9f9f9;
  }
  
  .login-image {
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .login-image img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  .login-form {
    flex: 1;
    max-width: 400px;
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin: 15px 0;
  }
  
  .input-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .login-button {
    background: #ff5733;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
  
  .social-login {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
  }
  
  .social-login button {
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
  }
  
  .social-login .facebook {
    background: #3b5998;
  }
  
  .social-login .google {
    background: #db4437;
  }
  
  .social-login .apple {
    background: black;
  }
  