.gaming-community {
    width: 100%;
    height: 300px;
    background-image: url('/Frame 2608774.png'); /* Replace with your actual image path */
    background-size: contain;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
  }
  
  .email-form {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    padding: 5px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #ddd;
    overflow: hidden;
  }
  
  .email-input {
    width: 220px;
    padding: 12px;
    border: none;
    outline: none;
    font-size: 14px;
    color: #9C8B85;
    font-weight: 500;
    border-radius: 8px 0 0 8px; /* Slightly rounded edges */
    background-color: transparent;
  }
  
  .join-button {
    background-color: #FF6B4A;
    color: white;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 0 30px 30px 0; /* Fully rounded only on the right side */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .join-button:hover {
    background-color: #E85B3C;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    z-index: 1000;
    text-align: center;
  }
  
  .popup-content {
    font-size: 16px;
  }
  
  .close-button {
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    background: #ff5e5e;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background: #e74c3c;
  }
  
  
  