* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}
.radio-container{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  color: #ed563b;
}
.dropdown:hover{
  color: #ed563b;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.hover:hover{
  color:  #ed563b;
}
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  bottom: 23px;
  right: 28px;
}

/* The popup form - hidden by default */
.form-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width input fields */
.form-container input[type=text], .form-container input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type=text]:focus, .form-container input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}
nav.css-c3j4wp-Nav {
position: absolute !important;
}
/* html {
  width: 100%;
    position: absolute;
} */
.mapouter img{
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .css-kdgvvr-Schedule{
    min-height: 0vh !important;
    padding: 80px 0 !important;
  }
}

/* Background Container */
/* .hero-container {
  position: relative;
  width: 100%;
  height: 700px;
  background-image: url("/BannerImg.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
} */

.hero-container {
  position: relative;
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background: url('/BannerImg.png') no-repeat center center/cover;
  z-index: 2;
}

/* Dark Overlay */
/* .hero-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5); 
} */

/* Content Wrapper */
/* .hero-content {
  position: relative;
  z-index: 10;
  max-width: 600px;
  padding: 20px;
} */

.hero-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 50, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
}

.hero-content h1 {
  color: white;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Button */
.hero-button {
  background-color: #e63946;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.hero-button:hover {
  background-color: #d62839;
}


/* Container */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: 48px 32px;
  
}

@media (min-width: 768px) {
  .about-container {
    flex-direction: row;
    padding: 48px 64px;
  }
}

/* Text Content */
.about-text {
  
  padding: 32px;
  
  border-left: 6px solid #047857; /* Green border */
  border-radius: 8px;
  border-bottom: 4px solid #047857; /* Green border */
  position: relative;
  
}

.about-text h2 {
  font-size: 24px;
  font-weight: 600;
  color: #1f2937;
}

.about-text p {
  color: #374151;
  margin-top: 16px;
}

.about-text ul {
  color: #374151;
  margin-top: 16px;
  padding-left: 20px;
  list-style-type: disc;
}

.about-text ul li {
  margin-bottom: 8px;
}

/* Green Shadow Effect */
/* .shadow-border {
  position: absolute;
  bottom: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border: 2px solid #047857;
  border-radius: 8px;
  opacity: 0.3;
} */

/* Image Section */
.about-image {
  position: relative;
  
}

@media (min-width: 768px) {
  .about-image {
    margin-top: 0;
    margin-left: 48px;
  }
}

.image-container img {
  width: 300px;
  height: 300px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Green Border & Shadow Effect */
/* .image-shadow-border {
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 100%;
  height: 100%;
  border: 2px solid #047857;
  border-radius: 8px;
  opacity: 0.5;
} */


/* Container */
.vision-container {
  display: flex;
  flex-direction: column;
  align-items: center;
 

  
}

@media (min-width: 768px) {
  .vision-container {
    flex-direction: row;
    padding: 48px 64px;
  }
}

/* Image Section */
.vision-image {
  position: relative;
  margin-bottom: 32px;
}

@media (min-width: 768px) {
  .vision-image {
    margin-bottom: 0;
    margin-right: 48px;
  }
}

.image-container img {
  width: 300px;
  height: 300px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Green Border & Shadow Effect */
/* .image-shadow-border {
  position: absolute;
  bottom: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border: 2px solid #047857;
  border-radius: 8px;
  opacity: 0.5;
} */

/* Text Content */
.vision-text {
 
  padding: 32px;
 
  border-right: 6px solid #047857; /* Green border */
  border-bottom: 4px solid #047857; /* Green border */
  border-radius: 8px;
  position: relative;

}

.vision-text h2 {
  font-size: 24px;
  font-weight: 600;
  color: #1f2937;
}

.vision-text p {
  color: #374151;
  margin-top: 16px;
}

.vision-text .quote {
  margin-top: 16px;
  color: #2563eb;
  font-weight: 600;
}

/* Green Shadow Effect */
/* .shadow-border {
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 100%;
  height: 100%;
  border: 2px solid #047857;
  border-radius: 8px;
  opacity: 0.3;
} */


.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: auto;
  padding: 40px;
}

.contact-image img {
  width: 350px;
  height: auto;
  border-radius: 8px;
}

.contact-content {
  flex: 1;
  padding: 0 40px;
}

.contact-content h2 {
  font-size: 28px;
  font-weight: bold;
  color: #0c1a36;
  margin-bottom: 20px;
}

.contact-form label {
  display: block;
  font-size: 14px;
  color: #7a7a7a;
  margin-top: 15px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: none;
  border-bottom: 2px solid #444;
  background: transparent;
  font-size: 16px;
  outline: none;
}

.contact-form textarea {
  height: 80px;
}

.contact-form button {
  background-color: #ff6b5b;
  color: white;
  padding: 12px 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 15px;
  cursor: pointer;
  font-weight: bold;
}

.contact-form button:hover {
  background-color: #ff4a3b;
}

.contact-info {
  padding-left: 40px;
}

.contact-info p {
  font-size: 16px;
  margin-bottom: 15px;
}

.contact-info strong {
  color: #0c1a36;
  font-size: 18px;
}
