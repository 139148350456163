.register {
  overflow-x: hidden;
  margin-bottom: 0.5%;
  padding-right: 14%;
  padding-left: 14%;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  /* height: 100vh;
    padding-bottom: 3%;
    margin-bottom: 3%; */
}
.form-box{
    background-color: #fff;
    padding:5%;
    margin-top:10px
}
.register-left {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}
.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #232527;

  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}
.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
  /* border-top-right-radius: 10% 50%;
    border-bottom-right-radius: 10% 50%; */
}
.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 25%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}
.eventimage img{
  width: -webkit-fill-available !important;
  max-width:700px;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
.register-headi{
    text-align: center;
padding-bottom: 2%;

}
.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}
div.row.register-form {
  padding-top: 3%;
  padding-bottom: 5%;
margin-top: 3%;
  font-family: Arial, Helvetica, sans-serif;
}
.btnRegister {
  /* float: right; */
  margin-left: 60%;

  border: none;
  border-radius: 1.5rem;
  padding: 1%;
  background: #0062cc;
  color: #fff;
  font-weight: 600;
  width: 50%;
  font-size: 18px;
  cursor: pointer;
}
.register .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #0062cc;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}
.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover {
  border: none;
}
.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.register-headi {
  text-align: center;

  color: #1b1c1d;
}
.vecto-header {
  margin-top: 10rem;
  color: azure;
}
.header-reg {
  color: azure;
}
.para-participation {
  /* line-height: 20px; */
  font-size: 0.8rem;
}
.checkbox {
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
}

.amount {
  font-size: 14px;
  /* margin-left: 50%; */
  margin-top: 20px;
  margin-left: 5px;
  font-size: 12px;
  border-radius: 4px;
  border : 1px solid #dee2e6
  ;
  padding :5px;
}
select#gender.select-option {
  word-wrap: normal;
  width: 50%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #dee2e6;
}
select#t-shirt.select-option {
  word-wrap: normal;
  width: 50%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #dee2e6;
}
.sign {
  margin-left: 5px;
  font-size: 14px;
  border-radius: 4px;
  border : 1px solid #dee2e6
  ;
  padding :5px;
}
.liability-color {
  color: red;
}

@media (max-width:600px){
.btnRegister {
  width: 40%!important;
}
.form-group-email-mobile{
  padding-top: 20px;
}
.eventimage img{
  width: -webkit-fill-available !important;
  width: 350px;
}
}